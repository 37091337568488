import moment from "moment";
import {
  formatNumber,
  parseDateToLocalAndFormat,
  renderDateWithTooltip,
  renderWithTooltip,
} from "../helpers/localHelperFunctions";
import { DATE_TIME_FORMAT_N2, DATE_TIME_FORMAT } from "./index";

export const PARTNER_CONFIGURATION_COLUMNS = [
  {
    key: "ipAddress",
    title: "Partner Name",
    dataIndex: "partnerName",
  },
  {
    key: "ipAddress",
    title: "IP Address",
    dataIndex: "ipAddress",
    render: renderWithTooltip,
  },
  {
    key: "ipAddress",
    title: "Frequency Range",
    render: renderWithTooltip,
    dataIndex: "frequencyRange",
  },
  {
    key: "ipAddress",
    title: "Created By",
    dataIndex: "createdBy",
    render: renderWithTooltip,
  },
  {
    key: "ipAddress",
    title: "Last Updated",
    dataIndex: "lastModifiedDate",
    render: (d) =>
      renderWithTooltip(parseDateToLocalAndFormat(d, DATE_TIME_FORMAT)),
  },
];

export const USER_LOGS_COLUMNS = [
  {
    key: "changeId",
    title: "Change ID",
    dataIndex: "changeId",
  },
  {
    key: "userId",
    title: "User ID",
    dataIndex: "userId",
    sorter: (a, b) => a.userId - b.userId,
  },
  {
    key: "email",
    title: "Email",
    dataIndex: "email",
  },
  {
    key: "date",
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) =>
      moment(a.date).format("YYYYMMDDHHmmss") -
      moment(b.date).format("YYYYMMDDHHmmss"),
    render: (d) =>
      renderWithTooltip(parseDateToLocalAndFormat(d, DATE_TIME_FORMAT)),
  },
  {
    key: "os",
    title: "OS",
    dataIndex: "os",
  },
  {
    key: "ipAddress",
    title: "IP Address",
    dataIndex: "ipAddress",
    render: renderWithTooltip,
  },
];

export const USER_LOGS_ADDITIONAL_COLUMNS = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
  },
  {
    key: "previous",
    dataIndex: "previous",
    title: "Previous Value",
    render: (val) => renderWithTooltip(val, 30),
  },
  {
    key: "current",
    dataIndex: "current",
    title: "Current Value",
    render: (val) => renderWithTooltip(val, 30),
  },
];

export const GAME_LIST_TABLE_COLUMNS = [
  {
    title: "Network",
    dataIndex: "networkName",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Game ID",
    dataIndex: "gameId",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Partner 1",
    dataIndex: "partner1",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Player 1 ID",
    key: "gameId",
  },
  {
    title: "Partner 2",
    dataIndex: "partner2",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Player 2 ID",
    key: "gameId",
  },
  {
    title: "Start Date",
    dataIndex: "startedDate",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Finish Date",
    dataIndex: "finishedDate",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Bet Amount",
    key: "gameId",
  },
  {
    title: "Win Amount",
    key: "gameId",
  },
  {
    title: "Game Type",
    dataIndex: "gameType",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Conditions",
    dataIndex: "conditions",
    key: "gameId",
  },
  {
    title: "Player 1 IP",
    dataIndex: "player1IP",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Player 2 IP",
    dataIndex: "player2IP",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "gameId",
  },
];

export const TOURNAMENT_LIST_TABLE_COLUMNS = [
  {
    title: "Network",
    key: "tournamentId",
  },
  {
    title: "Tour.ID",
    dataIndex: "tournamentId",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Tour.Name",
    dataIndex: "tournamentName",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Partner",
    dataIndex: "partners",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Game",
    dataIndex: "gameType",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Fee",
    key: "tournamentId",
  },
  {
    title: "Prize",
    key: "tournamentId",
  },
  {
    title: "Progress",
    dataIndex: "currentTourId",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Registration",
    dataIndex: "registredPlayerCount",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Min/Max Player",
    key: "tournamentId",
  },
  {
    title: "Started",
    dataIndex: "startDate",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Finished",
    dataIndex: "finishDate",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "tournamentId",
  },
];

// baseAmount,

export const TOURNAMENT_GENERATOR_LIST_COLUMNS = [
  {
    title: "Generator ID",
    key: "generatorId",
  },
  {
    title: "Tournament Name",
    dataIndex: "tournamentName",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Network",
    dataIndex: "networkName",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Partners",
    dataIndex: "partners",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Next Tournament Registration Date",
    dataIndex: "nextTournamentRegistrationDate",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Next Tournament Start Date",
    dataIndex: "nextTournamentStartDate",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Base Amount",
    dataIndex: "baseAmount",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Buy-in Amount",
    dataIndex: "buyIn",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Rebuy Count",
    dataIndex: "rebuyCount",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "First Rebuy Amount",
    dataIndex: "rebuyAmount",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Game Type",
    dataIndex: "gameType",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Repeat Count",
    key: "generatorId",
  },
  {
    title: "Last Regenerated Date",
    dataIndex: "lastRegeneratedDate",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Regeneration Interval(every)",
    key: "generatorId",
  },
  {
    title: "Score",
    key: "generatorId",
  },
  {
    title: "Game Convention",
    key: "generatorId",
  },
  {
    title: "Min/Max Players",
    key: "generatorId",
  },
  {
    title: "Places",
    dataIndex: "prizePlaces",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Creation Date",
    dataIndex: "creationDate",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "generatorId",
  },
];

export const TOURNAMENT_NEW_LIST_TABLE_COLUMNS = [
  {
    title: "Network",
    key: "tournamentId",
  },
  {
    title: "Partner",
    dataIndex: "partners",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Tour.ID",
    dataIndex: "tournamentId",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Tournament Name",
    dataIndex: "tournamentName",
    key: "tournamentName",
    render: renderWithTooltip,
  },
  {
    title: "Generator ID",
    dataIndex: "generatorId",
    key: "generatorId",
    render: renderWithTooltip,
  },
  {
    title: "Game",
    dataIndex: "gameType",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Fee",
    key: "tournamentId",
  },
  {
    title: "Prize",
    key: "tournamentId",
  },
  {
    title: "Progress",
    dataIndex: "currentTourOrder",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Registration",
    dataIndex: "registredPlayerCount",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Players",
    key: "tournamentId",
  },
  {
    title: "Started",
    dataIndex: "startDate",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Finished",
    dataIndex: "finishDate",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "tournamentId",
  },
];

export const DASHBOARD_GRAPHS_TABLE_COLUMNS = {
  OVERALL_STATISTICS: [
    {
      title: "",
      dataIndex: "key",
      key: "key",
      render: renderWithTooltip,
    },
    {
      title: "Uniq Players",
      dataIndex: "uniquePlayerCount",
      key: "key",
      render: renderWithTooltip,
    },
    {
      title: "Game Count",
      dataIndex: "gameCount",
      key: "key",
      render: renderWithTooltip,
    },
    {
      title: "Rake",
      dataIndex: "rakeSum",
      key: "key",
      render: renderWithTooltip,
    },
  ],
  TOP_WINNERS: [
    {
      title: "Player ID",
      dataIndex: "playerId",
      key: "gameId",
      render: renderWithTooltip,
    },
    {
      title: "Game ID",
      dataIndex: "gameId",
      key: "gameId",
      render: renderWithTooltip,
    },
    {
      title: "Bet Amount",
      dataIndex: "betAmount",
      key: "gameId",
      render: renderWithTooltip,
    },
    {
      title: "Win Amount",
      dataIndex: "winAmount",
      key: "gameId",
      render: renderWithTooltip,
    },
    {
      title: "Game Date",
      dataIndex: "createDate",
      key: "gameId",
      render: (info) =>
        renderWithTooltip(parseDateToLocalAndFormat(info, DATE_TIME_FORMAT_N2)),
    },
  ],
};

export const GAMES_REPORT_TABLE_COLUMNS = [
  {
    key: "gameId",
    title: "Network Name",
    dataIndex: "networkName",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    dataIndex: "partner1",
    title: "Partner1 Name",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    dataIndex: "partner2",
    title: "Partner2 Name",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "PlayerId 1",
    dataIndex: "player1NickName",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "PlayerId 2",
    dataIndex: "player2NickName",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Winner ID",
    dataIndex: "winnerNickName",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Game Kind",
    dataIndex: "gameKind",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Game Type",
    dataIndex: "gameType",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Game ID",
    dataIndex: "gameId",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Currency",
    dataIndex: "currency",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Bet",
  },
  {
    key: "gameId",
    title: "Win",
  },
  {
    key: "gameId",
    title: "Game Status",
    dataIndex: "finishStatus",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Start Date",
    dataIndex: "startedDate",
    render: renderWithTooltip,
  },
  {
    key: "networkId",
    title: "Finish Date",
    dataIndex: "finishedDate",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Player1 IP",
    dataIndex: "player1IP",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Player2 IP",
    dataIndex: "player2IP",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Player1 Round ID",
    dataIndex: "player1RoundId",
    render: renderWithTooltip,
  },
  {
    key: "gameId",
    title: "Player2 Round ID",
    dataIndex: "player2RoundId",
    render: renderWithTooltip,
  },
];

export const BETS_REPORT_TABLE_COLUMNS = [
  {
    title: "Network",
    dataIndex: "networkName",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Partner",
    dataIndex: "partnerName",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Player ID",
    dataIndex: "playerId",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Game Kind",
    dataIndex: "gameKind",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Game Type",
    dataIndex: "gameType",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    key: "networkId",
    title: "Currency",
    dataIndex: "currency",
    render: renderWithTooltip,
  },
  {
    title: "Round Id",
    dataIndex: "roundId",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Bet Amount",
    key: "networkId",
  },
  {
    title: "Win Amount",
    key: "networkId",
  },
  {
    title: "Rake Amount",
    key: "networkId",
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Finish Date",
    dataIndex: "finishDate",
    key: "networkId",
    render: renderWithTooltip,
  },
];

export const TOURNAMENT_REPORT_COLUMNS = [
  {
    title: "Network",
    dataIndex: "networkName",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Partner",
    dataIndex: "partners",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Tournament ID",
    dataIndex: "tournamentId",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "End Date",
    dataIndex: "endDate",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Buy-In Count",
    dataIndex: "buyinCount",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Buy-In Price",
    key: "tournamentId",
  },
  {
    title: "Rebuy Amount",
    key: "tournamentId",
  },
  {
    title: "Rebuy Count",
    dataIndex: "rebuyCount",
    key: "tournamentId",
    render: renderWithTooltip,
  },
  {
    title: "Change Amount",
    key: "tournamentId",
  },
  {
    title: "Prize",
    key: "tournamentId",
  },
  {
    title: "Tour. Overlay",
    key: "tournamentId",
  },
];

export const PLAYERS_REPORT_COLUMNS = [
  {
    title: "Network",
    dataIndex: "network",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Partner",
    dataIndex: "partner",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Player ID",
    dataIndex: "playerId",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Opponent ID",
    dataIndex: "opponentId",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Game Kind",
    dataIndex: "gameKind",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Game Type",
    dataIndex: "gameType",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    key: "networkId",
    title: "Currency",
    dataIndex: "currency",
    render: renderWithTooltip,
  },
  {
    title: "Round Id",
    dataIndex: "cycleId",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Bet Amount",
    key: "networkId",
  },
  {
    title: "Win Amount",
    key: "networkId",
  },
  {
    title: "Rake Amount",
    key: "networkId",
  },
  {
    title: "Start Date",
    dataIndex: "startedDate",
    key: "networkId",
    render: renderWithTooltip,
  },
  {
    title: "Finish Date",
    dataIndex: "finishedDate",
    key: "networkId",
    render: renderWithTooltip,
  },
];

export const TOURNAMENT_DETAILS_COLUMNS = [
  {
    title: "Tours",
    dataIndex: "tourOrder",
    key: "tourId",
    render: renderWithTooltip,
  },
  {
    title: "Players",
    dataIndex: "playerCount",
    key: "tourId",
    render: renderWithTooltip,
  },
  {
    title: "Rebuy Amounts",
    dataIndex: "rebuyAmount",
    key: "tourId",
    render: renderWithTooltip,
  },
  {
    title: "Rebuy",
    dataIndex: "rebuyCount",
    key: "tourId",
    render: renderWithTooltip,
  },
  {
    title: "Start Date",
    dataIndex: "startedDate",
    key: "tourId"
  },
  {
    title: "End Date",
    dataIndex: "finishedDate",
    key: "tourId"
  },
];

export const TOURNAMENT_TOURS_COLUMNS = [
  {
    title: "Game ID",
    dataIndex: "gameId",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Player 1 ID",
    key: "gameId",
  },
  {
    title: "Player 1 Changes",
    key: "gameId",
  },
  {
    title: "Score",
    key: "gameId",
  },
  {
    title: "Player 2 ID",
    key: "gameId",
  },
  {
    title: "Player 2 Changes",
    key: "gameId",
  },
  {
    title: "Player 1 IP",
    key: "gameId",
  },
  {
    title: "Player 2 IP",
    key: "gameId",
  },
];

export const TOURNAMENT_PRIZE_DISTRIBUTION_COLUMNS = [
  {
    title: "Places",
    dataIndex: "place",
    key: "place",
    render: renderWithTooltip,
  },
  {
    title: "Amounts",
    dataIndex: "",
    key: "place",
    render: (data) =>
      renderWithTooltip(
        `${formatNumber(data.prizeAmount, true)} ${data.currencyId}`
      ),
  },
  {
    title: "Percentage",
    dataIndex: "prizePercentage",
    key: "place",
    render: renderWithTooltip,
  },
  {
    title: "Player(s) ID",
    dataIndex: "players",
    key: "place",
  },
];

export const TOURNAMENT_CHANGES_COLUMNS = [
  {
    title: "Game ID",
    dataIndex: "gameId",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Player ID",
    dataIndex: "playerNickName",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Quantity",
    dataIndex: "changeCount",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Price",
    dataIndex: "",
    key: "gameId",
    render: (data) => renderWithTooltip(`${data.priceSum} ${data.currency}`),
  },
];

export const TOURNAMENT_PLAYERS_COLUMNS = [
  {
    title: "Player ID",
    dataIndex: "playerNickname",
    key: "playerNickName",
    render: (data) => renderWithTooltip(data, 30),
  },
  {
    title: "Status",
    dataIndex: "playerStatus",
    key: "playerNickName",
    render: (data) => renderWithTooltip(data, 30),
  },
  {
    title: "Time",
    dataIndex: "eventTime",
    key: "playerNickName",
    render: (data) => renderWithTooltip(data, 30),
  },
  {
    title: "Added By",
    dataIndex: "operatorName",
    key: "playerNickName",
    render: (data) => renderWithTooltip(data, 30),
  },
  {
    title: "Comments",
    dataIndex: "comment",
    key: "playerNickName",
    render: (data) => renderWithTooltip(data, 30),
  },
];

export const NETWORKS_LIST_COLUMNS = [
  {
    title: "Network Name",
    dataIndex: "networkName",
    key: "networkID",
    render: renderWithTooltip,
  },
  {
    title: "Network ID",
    dataIndex: "networkID",
    key: "networkID",
    render: renderWithTooltip,
  },
  {
    title: "Network Type",
    dataIndex: "networkType",
    key: "networkID",
    render: renderWithTooltip,
  },
  {
    title: "Partners",
    dataIndex: "partners",
    key: "networkID",
    render: renderWithTooltip,
  },
  {
    title: "Game",
    dataIndex: "game",
    key: "networkID",
    render: renderWithTooltip,
  },
  {
    title: "Game Type",
    dataIndex: "gameType",
    key: "networkID",
    render: renderWithTooltip,
  },
];

export const CURRENCY_LIST_COLUMNS = [
  {
    title: "Iso Code",
    dataIndex: "id",
    key: "currencyId",
  },
  {
    title: "Description",
    dataIndex: "title",
    key: "currencyId",
  },
];

export const PARTNERS_LIST_COLUMNS = [
  {
    title: "Partner Name",
    dataIndex: "partnerName",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Partner ID",
    dataIndex: "partnerID",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Partner Site",
    dataIndex: "partnerSiteID",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Secret Key",
    dataIndex: "secretKey",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "External Partner ID",
    dataIndex: "externalPartnerID",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "API Domain",
    dataIndex: "apiDomain",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Network",
    dataIndex: "network",
    key: "uniqKey",
  },
  {
    title: "Other",
    key: "uniqKey",
  },
];

export const JACKPOT_LIST_COLUMNS = [
  {
    title: "Network",
    dataIndex: "network",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Partner",
    dataIndex: "partner",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Game ID",
    dataIndex: "gameId",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Game Name",
    dataIndex: "gameKind",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Game Type",
    dataIndex: "gameType",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Jackpot Name",
    dataIndex: "jackpotName",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Player ID",
    dataIndex: "playerNickName",
    key: "uniqKey",
    render: renderWithTooltip,
  },
  {
    title: "Bet Amount",
    key: "uniqKey",
  },
  {
    title: "Win Amount",
    key: "uniqKey",
  },
  {
    title: "Date / Time",
    dataIndex: "eventDate",
    key: "uniqKey",
    render: renderDateWithTooltip,
  },
];

export const LUCKY_SPIN_COLUMNS = [
  {
    title: "Game ID",
    dataIndex: "gameId",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Player ID",
    dataIndex: "playerNickName",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Game Type",
    dataIndex: "gameType",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Stake",
    key: "gameId",
  },
  {
    title: "Win",
    key: "gameId",
  },
  {
    title: "Factor",
    dataIndex: "factor",
    key: "gameId",
    render: renderWithTooltip,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "gameId",
    render: renderDateWithTooltip,
  },
  {
    title: "Player IP",
    dataIndex: "ip",
    key: "gameId",
    render: renderWithTooltip,
  },
];

export const RAKE_RACE_COLUMNS = [
  {
    title: "Network",
    dataIndex: "networkName",
    key: "rakeRaceId",
    render: renderWithTooltip,
  },
  {
    title: "Partner",
    dataIndex: "partners",
    key: "rakeRaceId",
    render: renderWithTooltip,
  },
  {
    title: "Game",
    dataIndex: "games",
    key: "rakeRaceId",
    render: renderWithTooltip,
  },
  {
    title: "Game Type",
    dataIndex: "gameTypes",
    key: "rakeRaceId",
    render: renderWithTooltip,
  },
  {
    title: "Prize Fund",
    dataIndex: "",
    key: "rakeRaceId",
    render: (data) => renderWithTooltip(`${data.prizeFund} ${data.currency}`),
  },
  {
    title: "Prize Places",
    dataIndex: "prizePlace",
    key: "rakeRaceId",
    render: renderWithTooltip,
  },
  {
    title: "Rake Race Name",
    dataIndex: "rakeRaceName",
    key: "rakeRaceId",
    render: renderWithTooltip,
  },
  {
    title: "Rake Race ID",
    dataIndex: "rakeRaceId",
    key: "rakeRaceId",
    render: renderWithTooltip,
  },
  {
    title: "Started",
    dataIndex: "startDate",
    key: "rakeRaceId",
    render: (d) =>
      renderWithTooltip(parseDateToLocalAndFormat(d, DATE_TIME_FORMAT)),
  },
  {
    title: "Finished",
    dataIndex: "finishDate",
    key: "rakeRaceId",
    render: (d) =>
      renderWithTooltip(parseDateToLocalAndFormat(d, DATE_TIME_FORMAT)),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "rakeRaceId",
  },
];

export const RAKE_RACE_PLAYERS_LIST_COLUMNS = [
  {
    title: "Player ID",
    dataIndex: "playerId",
    key: "id",
    render: renderWithTooltip,
  },
  {
    title: "Game ID",
    dataIndex: "gameId",
    key: "id",
    render: renderWithTooltip,
  },
  {
    title: "Bet Amount",
    dataIndex: "",
    key: "id",
    render: (data) => renderWithTooltip(`${data.betAmount} ${data.currency}`),
  },
  {
    title: "Points",
    dataIndex: "point",
    key: "id",
    render: renderWithTooltip,
  },
  {
    title: "Update Date",
    dataIndex: "updateDate",
    key: "id",
    render: (d) =>
      renderWithTooltip(parseDateToLocalAndFormat(d, DATE_TIME_FORMAT_N2)),
  },
];

export const RAKE_RACE_RESULTS_LIST_COLUMNS = [
  {
    title: "Place",
    dataIndex: "place",
    key: "place",
    render: renderWithTooltip,
  },
  {
    title: "Player ID",
    dataIndex: "playerId",
    key: "place",
    render: renderWithTooltip,
  },
  {
    title: "Total Point",
    dataIndex: "totalPoint",
    key: "place",
    render: renderWithTooltip,
  },
  {
    title: "Win",
    dataIndex: "fullWin",
    key: "place",
    render: renderWithTooltip,
  },
];

export const USERS_COLUMNS1 = [
  {
    title: "User ID",
    dataIndex: "id",
    key: "id",
    render: renderWithTooltip,
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "id",
    render: renderWithTooltip,
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "id",
    render: renderWithTooltip,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "id",
    render: renderWithTooltip,
  },
];

export const USERS_COLUMNS2 = [
  {
    title: "Partner(s)",
    dataIndex: "partners",
    key: "id",
    render: renderWithTooltip,
  },
  {
    title: "Creation Date",
    dataIndex: "creationDate",
    key: "id",
    render: renderDateWithTooltip,
  },
  {
    title: "Expiration Date",
    dataIndex: "expirationDate",
    key: "id",
    render: renderDateWithTooltip,
  },
  {
    title: "Created By",
    dataIndex: "creator",
    key: "id",
    render: renderWithTooltip,
  },
];
